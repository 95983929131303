var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { FileUrlGenerateRequest } from '@millerknoll/pub-api-schema';
import { fileService } from 'services/api/files-service';
import { generateFileTypeRegex } from 'services/helperFunctions/handleRegex';
function fileSelectHandler(dataTransferFiles, allowedFileTypes, maxFileSizeMb, maxFilenameLength) {
    var _a;
    if (!dataTransferFiles) {
        return { file: undefined, message: 'File select error' };
    }
    var file = (_a = dataTransferFiles === null || dataTransferFiles === void 0 ? void 0 : dataTransferFiles.item) === null || _a === void 0 ? void 0 : _a.call(dataTransferFiles, 0);
    if (!file)
        return { file: undefined, message: undefined };
    if ((dataTransferFiles === null || dataTransferFiles === void 0 ? void 0 : dataTransferFiles.length) > 1) {
        return { file: undefined, message: 'Limit one file upload at a time' };
    }
    var maxFileSize = 1024 * 1024 * (maxFileSizeMb !== null && maxFileSizeMb !== void 0 ? maxFileSizeMb : 50);
    if (file.size > maxFileSize) {
        return { file: undefined, message: "File size too large. Max file size: ".concat(maxFileSizeMb, "MB") };
    }
    if (maxFilenameLength) {
        if (file.name.length > maxFilenameLength) {
            return {
                file: undefined,
                message: "File name length too long. Max file name length: ".concat(maxFilenameLength),
            };
        }
    }
    if (allowedFileTypes && allowedFileTypes.length > 0) {
        var validType = allowedFileTypes.find(function (fileType) { return generateFileTypeRegex(fileType).test(file.type); });
        if (!validType) {
            return { file: undefined, message: "Allowed file types: ".concat(allowedFileTypes.join(', ')) };
        }
    }
    return { file: file, message: undefined };
}
function uploadDrawing(drawingFile, cpn) {
    return __awaiter(this, void 0, void 0, function () {
        var url, fileUploadOptions;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fileService.generateUrl({
                        requestType: FileUrlGenerateRequest.requestType.UPLOAD,
                        path: "".concat(cpn, "/images/").concat(drawingFile.name),
                    })];
                case 1:
                    url = _a.sent();
                    fileUploadOptions = {
                        method: 'PUT',
                        headers: {
                            'Content-Type': drawingFile.type,
                        },
                        body: drawingFile,
                        redirect: 'follow',
                    };
                    return [4 /*yield*/, fetch(url.body.url, fileUploadOptions)];
                case 2:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
var fileUploadHelper = { fileSelectHandler: fileSelectHandler, uploadDrawing: uploadDrawing };
export { fileUploadHelper };

/* eslint-disable @typescript-eslint/no-explicit-any */
import NodeCache from 'node-cache';
var CACHE_TTL_IN_MINUTES = 5;
var myCache = new NodeCache({ stdTTL: CACHE_TTL_IN_MINUTES * 60 });
function setCache(key, value, ttlInSeconds) {
    if (ttlInSeconds === void 0) { ttlInSeconds = CACHE_TTL_IN_MINUTES * 60; }
    myCache.set(key, value, ttlInSeconds);
}
function getCache(key) {
    return myCache.get(key);
}
var cacheHelper = {
    setCache: setCache,
    getCache: getCache,
};
export { cacheHelper };

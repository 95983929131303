var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useCallback, useEffect, useState } from 'react';
import { validate } from 'services/api/product-service';
import { cacheHelper } from 'utils/cache-helper';
import { priceService } from 'services/api/price-service';
import { dateFormatHelper } from 'utils/date-format-helper';
/**
 *
 * @param productReference product number/reference to be used for validate and get-list-price calls
 * @param cpn used as part of cache key
 * @param disableCache set true to disable caching when making get-list-price call
 * @param skipGetPrice set true to prevent validate and get-list-price calls
 * @param persistentValidate set true to keep validating the product until it is validated (in PubLayer)
 * @param intervalInSeconds interval to be used with persistentValidate in seconds. default: 10s
 * @returns response of get-list-price API or error response of validate API
 */
export function useGetListPriceWithValidate(productReference, cpn, disableCache, skipGetPrice, saving, persistentValidate, intervalInSeconds) {
    var _this = this;
    var _a = useState(false), loading = _a[0], setLoading = _a[1];
    var _b = useState(-1), status = _b[0], setStatus = _b[1];
    var _c = useState(), body = _c[0], setBody = _c[1];
    var _d = useState(), isValidated = _d[0], setIsValidated = _d[1];
    var getPrice = useCallback(function () { return __awaiter(_this, void 0, void 0, function () {
        var validateKey, validateRsp, key, rsp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!productReference || skipGetPrice || saving)
                        return [2 /*return*/, undefined];
                    setLoading(true);
                    validateKey = "validate_".concat(cpn, "_").concat(productReference);
                    validateRsp = cacheHelper.getCache(validateKey);
                    if (!!validateRsp) return [3 /*break*/, 2];
                    return [4 /*yield*/, validate({
                            productNbr: productReference,
                        })];
                case 1:
                    validateRsp = _a.sent();
                    _a.label = 2;
                case 2:
                    if (!(validateRsp.status !== 200)) return [3 /*break*/, 3];
                    setIsValidated(false);
                    setBody({
                        returnCode: -1,
                        returnMessage: validateRsp.body.message,
                    });
                    setStatus(validateRsp.status);
                    setLoading(false);
                    return [3 /*break*/, 6];
                case 3:
                    setIsValidated(true);
                    cacheHelper.setCache(validateKey, validateRsp, 5 * 60);
                    key = "".concat(cpn, "_").concat(productReference);
                    rsp = void 0;
                    if (!disableCache) {
                        rsp = cacheHelper.getCache(key);
                    }
                    if (!!rsp) return [3 /*break*/, 5];
                    return [4 /*yield*/, priceService.getListPrice({
                            effectiveDate: dateFormatHelper.todaysDateFormattedLocale({ format: 'yyyyMMdd' }),
                            productNumber: productReference,
                        })];
                case 4:
                    rsp = _a.sent();
                    _a.label = 5;
                case 5:
                    if (!disableCache) {
                        cacheHelper.setCache(key, rsp, 5 * 60);
                    }
                    setBody(rsp.body);
                    setStatus(rsp.status);
                    setLoading(false);
                    _a.label = 6;
                case 6: return [2 /*return*/, undefined];
            }
        });
    }); }, [cpn, disableCache, productReference, skipGetPrice, saving]);
    useEffect(function () {
        (function initialGetPrice() {
            return __awaiter(this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!productReference || skipGetPrice || saving)
                                return [2 /*return*/];
                            if (!(isValidated !== false || !persistentValidate)) return [3 /*break*/, 2];
                            return [4 /*yield*/, getPrice()];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPrice, saving]);
    useEffect(function () {
        if (!productReference || skipGetPrice || isValidated === undefined || saving)
            return undefined;
        if (!isValidated && persistentValidate) {
            var interval_1 = setInterval(function () { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, getPrice()];
                        case 1:
                            _a.sent();
                            return [2 /*return*/];
                    }
                });
            }); }, intervalInSeconds ? intervalInSeconds * 1000 : 10000);
            return function () { return clearInterval(interval_1); };
        }
        return undefined;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPrice, isValidated, productReference, skipGetPrice, saving]);
    return { loading: loading, status: status, body: body, isValidated: isValidated };
}
